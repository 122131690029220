import React from "react";
import {
  ButtonDiv,
  ButtonText,
  Container,
  StyledDiv,
  StyledSpan,
} from "./LandingSection.styles";

export const LandingSection = ({ handleBookClick }) => {
  return (
    <Container>
      <StyledDiv>
        <StyledSpan>
          GIVE YOUR PLACE A CLEAN <br />
          LOOK
        </StyledSpan>
        <br />
        <br />
      </StyledDiv>
      <ButtonDiv onClick={() => handleBookClick("contact")}>
        <ButtonText>Book now</ButtonText>
      </ButtonDiv>
    </Container>
  );
};
