import React, { useEffect, useState } from "react";
import {
  Container,
  LinkText,
  NavBarLinks,
  SVGContainer,
} from "./NavBar.styles";
import MainLogo from "../MainLogo";

export const NavBar = ({ onLinkClick, innerRef }) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Container ref={innerRef} scrolled={scrolled}>
      <SVGContainer>
        <MainLogo color="black" />
      </SVGContainer>
      <NavBarLinks>
        <LinkText
          onClick={() => onLinkClick("services")}
          scrolled={scrolled}
          tabIndex="1"
        >
          Services
        </LinkText>
        <LinkText
          onClick={() => onLinkClick("faq")}
          scrolled={scrolled}
          tabIndex="1"
        >
          FAQs
        </LinkText>
        <LinkText
          onClick={() => onLinkClick("contact")}
          scrolled={scrolled}
          tabIndex="1"
        >
          Get a quote
        </LinkText>
      </NavBarLinks>
    </Container>
  );
};
