import styled from "styled-components";
import { COLORS } from "../../assets/Colors";

export const Container = styled.div`
  display: flex;
  background: linear-gradient(
    to bottom,
    #efefef 30%,
    rgba(67, 142, 242, 0.1) 100%
  );
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ccc;
`;

export const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 3%;
`;

export const FAQHeaderText = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${COLORS.secondary};
  margin-bottom: 10px;
`;

export const SectionTitle = styled.span`
  font-size: 37px;
  font-weight: bold;
  color: black;
  margin-bottom: 20px;

  @media (max-width: 900px) {
    font-size: 30px;
  }
`;

export const FAQDescription = styled.span`
  display: flex;
  font-size: 20px;
  color: #333;
  width: 60%;
  text-align: center;

  @media (max-width: 900px) {
    width: 90%;
    margin-bottom: 5%;
    font-size: 18px;
  }
`;

export const QuestionsDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-bottom: 3%;
  flex-direction: column;
`;
