import { AccordianCopy } from "./AccordianCopy";
import { QuestionPanel } from "./QuestionPanel";

export const FAQAccordian = () => {
  return AccordianCopy.map((panel, index) => (
    <QuestionPanel
      question={panel.question}
      answer={panel.answer}
      key={index}
    />
  ));
};
