export const AccordianCopy = [
  {
    question: "What areas do you cover?",
    answer:
      "We cover Surrey and London. If you're unsure whether we cover your location, feel free to contact us.",
  },
  {
    question: "How do I book a service?",
    answer:
      "You can either give us a call at 0747 9937 019 or use our contact form on the website and we will get back to you ASAP.",
  },
  {
    question: "Do you bring your own cleaning supplies and equipment?",
    answer:
      "Yes, we have everything. If you want us to use specific equipment, just let us know!",
  },
  {
    question: "Can I request specific cleaning tasks or customize my service?",
    answer:
      "Yes of course, just let us know everything beforehand and we can sort it out. ",
  },
  {
    question: "What happens if I'm not satisfied with the cleaning?",
    answer:
      "We offer a 24 hour free of charge return policy to ensure you are satisfied.",
  },
  {
    question: "How far in advance do I need to book a cleaning appointment?",
    answer:
      "2 days prior to the clean. Any sooner will come with a fixed fee of £25",
  },
  {
    question: "Do you offer weekly, bi-weekly or monthly cleaning?",
    answer: "Yes, we offer all of the above.",
  },
];
