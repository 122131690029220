import styled from "styled-components";
import { COLORS } from "../../assets/Colors";

export const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 60px;

  @media (max-width: 900px) {
    margin: 40px;
  }
`;

export const BlueHeaderText = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${COLORS.secondary};
  margin-bottom: 10px;
`;

export const HeaderTitle = styled.span`
  font-size: 37px;
  font-weight: bold;
  color: black;
  margin-bottom: 20px;

  @media (max-width: 900px) {
    font-size: 30px;
  }
`;

export const HeaderDescription = styled.span`
  display: flex;
  font-size: 20px;
  color: #333;
  width: 60%;
  text-align: center;
  justify-content: center;

  @media (max-width: 900px) {
    width: 90%;
    margin-bottom: 5%;
    font-size: 18px;
  }
`;
