import {
  ContactInfoDiv,
  ContactInfoTitle,
  ContactInfoValue,
  EmailIcon,
  IconAndTextDiv,
  InfoTitle,
  PhoneIcon,
  Container,
} from "./ContactInfo.styles";

export const ContactInfo = () => {
  return (
    <Container>
      <InfoTitle>Contact info</InfoTitle>
      <ContactInfoDiv>
        <IconAndTextDiv>
          <PhoneIcon />
          <ContactInfoTitle>Mobile</ContactInfoTitle>
        </IconAndTextDiv>
        <ContactInfoValue>+44 759725694</ContactInfoValue>
      </ContactInfoDiv>
      <ContactInfoDiv>
        <IconAndTextDiv>
          <EmailIcon />
          <ContactInfoTitle>Email</ContactInfoTitle>
        </IconAndTextDiv>
        <ContactInfoValue>info@veloxx.co.uk</ContactInfoValue>
      </ContactInfoDiv>
    </Container>
  );
};
