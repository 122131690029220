import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 80px;
  width: 100%;
  background-color: ${(props) => (props.scrolled ? "white" : "transparent")};
  top: 0;
  position: fixed;
  z-index: 5;
  transition: background-color 300ms ease-in-out;
  box-shadow: ${(props) => (props.scrolled ? "0px 1px 4px #aaa;" : "none")};

  @media (max-width: 900px) {
    height: 80px;
    position: fixed;
    box-shadow: ${(props) => (props.scrolled ? "0px 2px 5px #999" : "none")};
  }
`;

export const SVGContainer = styled.div`
  display: flex;
  flex: 0.1;

  @media (max-width: 900px) {
    flex: 0.3;
  }
`;

export const NavBarLinks = styled.div`
  display: flex;
  flex: 0.83;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    flex: 1;
  }
`;

export const LinkText = styled.a`
  position: relative;
  text-decoration: none;
  letter-spacing: 0.3px;
  font-weight: 600;
  font-size: 17px;
  color: ${(props) => (props.scrolled ? "black" : "white")};
  cursor: pointer;
  margin: 0px 20px;
  text-wrap: nowrap;

  &:after {
    content: "";
    position: absolute;
    background-color: ${(props) => (props.scrolled ? "black" : "white")};
    border-radius: 50px;
    height: 1px;
    width: 0%;
    left: 0px;
    bottom: -10px;
    transition: width 350ms ease-in-out;
  }

  &:hover:after {
    width: 100%;
  }

  @media (max-width: 900px) {
    font-size: 14px;
  }
`;
