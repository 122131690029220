export const ServiceSelectorCopy = [
  {
    title: "Office/Workplace",
  },
  {
    title: "Communal area",
  },
  {
    title: "End of tenancy",
  },
  {
    title: "Post renovation",
  },
];
