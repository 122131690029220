import styled from "styled-components";
import { COLORS } from "../../assets/Colors";

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  gap: 10px;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const ServiceDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  padding: 3% 1%;
  cursor: pointer;
  color: black;
  border: 1px solid ${COLORS.secondary};
  background-color: white;

  @media (min-width: 900px) {
    &:hover {
      transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1),
        color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
      background-color: #333;
      color: white;
    }
  }

  ${({ active }) =>
    active &&
    `
    background-color: ${COLORS.black};
    color: white;
  `}

  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const ServiceText = styled.span`
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
`;
