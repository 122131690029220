import styled, { css } from "styled-components";
import { COLORS } from "../../assets/Colors";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 30px;
  background-color: #f9f9f9; /* Example light color */
  border-top: 1px solid #ccc;

  @media (max-width: 900px) {
    padding-bottom: 0px;
  }
`;

export const ContactDiv = styled.div`
  display: flex;
  position: relative;
  right: 2%;
  margin: 0px 6%;
  flex-direction: row;

  @media (max-width: 900px) {
    flex-direction: column;
    margin: 0px;
    right: 0%;
  }
`;

const textInputStyles = css`
  height: 40px;
  border-radius: 2px;
  background-color: white;
  border: 1px solid #555;
  padding-left: 5px;
  font-size: 16px;
  font-family: inherit;
  font-size: inherit;

  &::placeholder {
    opacity: 0.8;
    font-size: 15px;
  }

  &:focus {
    outline: 2px solid ${COLORS.secondary};
  }

  @media (max-width: 900px) {
    font-size: 16px;
  }
`;

export const ContactForm = styled.div`
  display: flex;
  flex: 0.7;
  background-color: #e3e3e3;
  flex-direction: column;
  padding-bottom: 5%;

  @media (max-width: 900px) {
    font-size: 25px;
    padding-bottom: 5%;
  }
`;

export const FormTitle = styled.h2`
  font-size: 30px;
  font-weight: bold;
  color: black;
  text-align: center;
  margin-bottom: 5%;

  @media (max-width: 900px) {
    font-size: 25px;
  }
`;

export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0% 5% 0% 17%;

  @media (max-width: 900px) {
    margin: 0% 5%;
  }
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5%;

  @media (max-width: 900px) {
    margin-bottom: 7%;
  }
`;

export const FieldLabel = styled.span`
  margin-bottom: 5px;
  font-size: ${(props) => (props.isHouseFormField ? "13px" : "15px")};
  font-weight: ${(props) => (props.isHouseFormField ? "500" : "bold")};
`;

export const FormInput = styled.input`
  ${textInputStyles}
`;

export const FormNumberInput = styled.input`
  ${textInputStyles}
  width: 20%;
`;

export const FormTextArea = styled.textarea`
  ${textInputStyles};
  resize: none;
  height: 80px;
  padding-top: 5px;
`;

export const HomeDetailsDiv = styled.div`
  padding: 10px 20px;
  background-color: #438ef233;
  margin-bottom: 10px;
  border-radius: 2px;
`;

export const HomeDetails = styled.span`
  font-size: 18px;
  color: #222;
  font-weight: bold;
`;

export const SubmitButton = styled.button`
  display: flex;
  height: 30px;
  color: white;

  border: none;
  border-radius: 2px;
  background-color: ${COLORS.black};
  padding: 20px 0px;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -1px;
  cursor: pointer;

  &:hover {
    transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    background-color: ${COLORS.secondary};
  }
`;

export const CautionText = styled.span`
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  margin: 4% 0px;
  font-weight: bold;
`;

export const OptionalText = styled.span`
  font-size: 11px;
  color: #444;
`;

export const ToastMainText = styled.span`
  font-size: 14px;
  margin-left: 10px;
`;

export const ToastSecondaryText = styled.span`
  font-size: 12px;
  color: #999;
`;
