import styled from "styled-components";
import backgroundImg from "../../assets/images/entryPic3.jpg";
import mobileBackgroundImg from "../../assets/images/mobileEntryPicVeloxx.jpg";
import { COLORS } from "../../assets/Colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 800px;
  justify-content: center;
  align-items: center;
  background-image: url(${backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 900px) {
    background-image: url(${mobileBackgroundImg});
    height: 750px;
  }

  @media (min-width: 1500px) {
    background-image: url(${mobileBackgroundImg});
    height: 900px;
  }
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 5%;
  padding: 50px;
`;

export const StyledSpan = styled.span`
  color: white;
  font-size: 55px;
  font-weight: bold;
  text-shadow: 0 1.5px 1.5px #000;

  @media (max-width: 900px) {
    font-size: 40px;
  }
`;

export const StlyedSpanMessage = styled.span`
  font-weight: 600;
  font-size: 23px;
  color: white;
  text-shadow: 0 1px 1px #000;
`;

export const ButtonDiv = styled.div`
  display: flex;
  width: 12%;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.secondary}ee;
  padding: 20px;
  cursor: pointer;
  transition: background-color 400ms cubic-bezier(0.17, 0.67, 0.57, 0.95);

  &:hover {
    background-color: ${COLORS.secondary}bb;
  }

  @media (max-width: 900px) {
    width: 25%;
  }

  @media (max-width: 450px) {
    width: 55%;
  }
`;

export const ButtonText = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: white;
`;
