import styled, { keyframes } from "styled-components";
import { IoChevronDown } from "react-icons/io5";
import { IoChevronUp } from "react-icons/io5";

const flipAnimation = keyframes`
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(180deg);
  }
`;

const reverseFlipAnimation = keyframes`
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
`;

export const QuestionAccordianDiv = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  padding: 20px;
  cursor: pointer;
  margin: 1% 0px;

  transition: background-color 300ms ease-in-out, box-shadow 200ms ease-in-out;

  &:hover {
    background-color: ${(props) => (props.open ? "#438ef211" : "#438ef222")};
  }

  @media (max-width: 900px) {
    width: 90%;
    margin: 3% 0px;
  }
`;

export const QuestionDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`;

export const QuestionSpan = styled.span`
  font-size: 20px;
  font-weight: 700;

  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

export const StyledDownChevron = styled(IoChevronDown)`
  font-size: 20px;
  cursor: pointer;
  animation: ${flipAnimation} 0.5s forwards;
`;

export const StyledUpChevron = styled(IoChevronUp)`
  font-size: 20px;
  cursor: pointer;
  animation: ${reverseFlipAnimation} 0.5s forwards;
`;

export const AnswerDiv = styled.div`
  display: ${(props) => (props.open ? "flex" : "none")};
  margin-top: 3%;

  @media (max-width: 900px) {
    margin-top: 4%;
  }
`;

export const AnswerSpan = styled.span`
  font-size: 18px;
  color: #000;
  line-height: 1.8em;
`;
