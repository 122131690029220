import React, { useState } from "react";
import {
  AnswerDiv,
  AnswerSpan,
  QuestionAccordianDiv,
  QuestionDiv,
  QuestionSpan,
  StyledDownChevron,
  StyledUpChevron,
} from "./QuestionPanel.styles";

export const QuestionPanel = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleAccordianPress = () => {
    setIsOpen(!isOpen);
  };

  return (
    <QuestionAccordianDiv open={isOpen} onClick={() => handleAccordianPress()}>
      <QuestionDiv>
        <QuestionSpan>{question}</QuestionSpan>
        {isOpen ? <StyledUpChevron /> : <StyledDownChevron />}
      </QuestionDiv>
      <AnswerDiv open={isOpen}>
        <AnswerSpan>{answer}</AnswerSpan>
      </AnswerDiv>
    </QuestionAccordianDiv>
  );
};
