import styled from "styled-components";
import { COLORS } from "../../assets/Colors";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 20px 0px 20px;
  padding-bottom: 60px;

  @media (max-width: 900px) {
    margin: 0px 15px;
  }
`;

export const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.1;
  align-items: center;
  justify-content: center;
  margin: 4% 0px;
`;

export const ServicesHeaderText = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${COLORS.secondary};
  margin-bottom: 10px;
`;

export const SectionTitle = styled.span`
  font-size: 37px;
  font-weight: bold;
  color: black;
  margin-bottom: 20px;

  @media (max-width: 900px) {
    font-size: 30px;
  }
`;

export const ServicesDescription = styled.span`
  display: flex;
  font-size: 20px;
  color: #333;
  width: 60%;
  text-align: center;

  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 5%;
    font-size: 18px;
  }
`;

export const CardsDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 95%;
  gap: 40px;
`;
