import OfficeImg from "../../assets/images/OfficeCardJPG.jpg";
import TenancyImg from "../../assets/images/TenancyJPG.jpg";
import CommunalImg from "../../assets/images/CommunalJPG.jpg";
import RenovationImg from "../../assets/images/RenovationJPG.jpg";

export const CardsCopy = [
  {
    title: "Office/Workplace",
    description:
      "Covers thorough sanitation, detailed dusting of workspaces and common areas",
    imgSource: `${OfficeImg}`,
    alt: "A workplace environment",
  },
  {
    title: "End of tenancy",
    description:
      "Ensures a complete refresh, covering everything from bathrooms to living-rooms leaving nothing untouched",
    imgSource: `${TenancyImg}`,
    alt: "Man moving boxes at the end of his tenancy",
  },
  {
    title: "Communal area",
    description:
      "Spotless cleaning of any shared spaces, such as: hallways, corriders, lobbies and many more",
    imgSource: `${CommunalImg}`,
    alt: "A communal area",
  },
  {
    title: "Post renovation",
    description:
      "Deep clean of your newly referbreshed house, dealt with extra care",
    imgSource: `${RenovationImg}`,
    alt: "Man renovating a house using a drill",
  },
];
