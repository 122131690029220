import styled from "styled-components";
import { COLORS } from "../../../assets/Colors";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

export const Container = styled.div`
  display: flex;
  flex: 0.3;
  background-color: ${COLORS.black};
  margin-top: 100px;
  height: 400px;
  padding: 30px 0px 30px 30px;
  position: relative;
  left: 8%;
  flex-direction: column;

  @media (max-width: 900px) {
    height: 100px;
    left: 0%;
    margin: 0px;
    padding: 5px 0px 0px 20px;
  }
`;

export const InfoTitle = styled.h2`
  font-size: 30px;
  font-weight: bold;
  color: #fefefe;
  margin-bottom: 15%;

  @media (max-width: 900px) {
    font-size: 25px;
    margin-bottom: 5%;
  }
`;

export const ContactInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15%;

  @media (max-width: 900px) {
    margin-bottom: 7%;
  }
`;

export const IconAndTextDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const ContactInfoTitle = styled.h2`
  font-size: 22px;
  color: white;
  font-weight: 500;
`;

export const ContactInfoValue = styled.span`
  color: #eee;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1px;
`;

export const PhoneIcon = styled(BsFillTelephoneFill)`
  font-size: 23px;
  color: white;
  margin-right: 20px;

  @media (max-width: 900px) {
    font-size: 20px;
    margin-right: 10px;
  }
`;

export const EmailIcon = styled(MdEmail)`
  font-size: 25px;
  color: white;
  margin-right: 20px;

  @media (max-width: 900px) {
    font-size: 20px;
    margin-right: 10px;
  }
`;
