import { CardsCopy } from "./Cards.copy";
import { ServiceCard } from "./ServiceCard";

export const Cards = () => {
  return CardsCopy.map((card, index) => (
    <ServiceCard
      title={card.title}
      description={card.description}
      imgSource={card.imgSource}
      key={index}
    />
  ));
};
