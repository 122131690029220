import React from "react";
import { Container, QuestionsDiv } from "./FAQSection.styles";
import { FAQAccordian } from "../../components/FAQAccordian/FAQAccordian";
import { SectionHeader } from "../../components/SectionHeader/SectionHeader";

export const FAQSection = ({ innerRef }) => {
  return (
    <Container ref={innerRef}>
      <SectionHeader
        headerConfig={{
          extra: "FAQs",
          title: "Frequently Asked Questions",
          description:
            "Got a question in mind? Check below we have probably answered it already!",
        }}
      />
      <QuestionsDiv>
        <FAQAccordian />
      </QuestionsDiv>
    </Container>
  );
};
