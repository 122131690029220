import { useRef } from "react";
import { NavBar } from "./components/NavBar/NavBar";
import { ContactContext } from "./Context.js";
import { useState } from "react";
import { useEffect } from "react";
import {
  ContactSection,
  FAQSection,
  LandingSection,
  ServicesSection,
} from "./page-sections/index.js";
import { Footer } from "./components/Footer/Footer.js";
import { Toaster } from "react-hot-toast";
import { COLORS } from "./assets/Colors.js";

function App() {
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const servicesSectionRef = useRef(null);
  const contactSectionRef = useRef(null);
  const FAQSectionRef = useRef(null);
  const navbarRef = useRef(null);

  const handleResize = () => {
    setIsTablet(window.innerWidth < 900);
  };

  const handleClick = (section) => {
    const navbarHeight = navbarRef.current.getBoundingClientRect().height;
    if (section === "services") {
      window.scrollTo({
        top: isTablet
          ? servicesSectionRef.current.offsetTop - 80
          : servicesSectionRef.current.offsetTop - 40,
        behavior: "smooth",
      });
    } else if (section === "faq") {
      window.scrollTo({
        top: isTablet
          ? FAQSectionRef.current.offsetTop - 100
          : FAQSectionRef.current.offsetTop - 80,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: contactSectionRef.current.offsetTop - navbarHeight,
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      <ContactContext.Provider value={{ contactSectionRef }}>
        <LandingSection handleBookClick={handleClick} />
        <NavBar onLinkClick={handleClick} innerRef={navbarRef} />
        <ServicesSection
          innerRef={servicesSectionRef}
          handleCardClick={handleClick}
        />
        <FAQSection innerRef={FAQSectionRef} />
        <ContactSection innerRef={contactSectionRef} />
        <Footer />
      </ContactContext.Provider>
      <Toaster
        toastOptions={{
          duration: 5000,
          position: isTablet ? "bottom-center" : "top-center",
          style: {
            borderRadius: 0,
            fontWeight: "bold",
            width: "100%",
            border: "1px solid black",
          },
          success: {
            duration: 5000,
            iconTheme: {
              primary: COLORS.secondary,
            },
          },
          error: {
            style: {
              fontSize: 13,
            },
          },
        }}
      />
    </div>
  );
}

export default App;
