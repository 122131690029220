import React from "react";
import { CardsDiv, Container } from "./ServicesSection.styles";

import { Cards } from "../../components/ServiceCard/Cards";
import { SectionHeader } from "../../components/SectionHeader/SectionHeader";

export const ServicesSection = ({ innerRef }) => {
  return (
    <Container id="Services" ref={innerRef}>
      <SectionHeader
        headerConfig={{
          extra: "Hire us today",
          title: "Cleaning services",
          description:
            "We are committed to gurantee client satisfaction with any service that we provide",
        }}
      />
      <CardsDiv>
        <Cards />
      </CardsDiv>
    </Container>
  );
};
