import React, { useContext } from "react";
import {
  Card,
  CardImg,
  CardTitle,
  ContentDiv,
  Description,
  ImageDiv,
} from "./ServiceCard.styles";
import { ContactContext } from "../../Context";
// import test from "../../assets/images/OfficeCardJPG.jpg";

export const ServiceCard = ({ title, description, imgSource, alt }) => {
  const { contactSectionRef } = useContext(ContactContext);

  const handleCardClick = () => {
    contactSectionRef.current.scrollIntoView({
      block: "center",
      inline: "center",
    });
  };

  return (
    <Card onClick={handleCardClick}>
      <ImageDiv>
        <CardImg src={imgSource} alt={alt} />
      </ImageDiv>
      <ContentDiv>
        <CardTitle>{title}</CardTitle>
        <Description>{description}</Description>
      </ContentDiv>
    </Card>
  );
};
