import React from "react";
import {
  CautionText,
  ContactDiv,
  ContactForm,
  Container,
  FieldLabel,
  FormDiv,
  FormField,
  FormInput,
  FormNumberInput,
  FormTextArea,
  FormTitle,
  HomeDetails,
  HomeDetailsDiv,
  OptionalText,
  SubmitButton,
  ToastMainText,
  ToastSecondaryText,
} from "./ContactSection.styles";
import { ContactInfo } from "./ContactInfo/ContactInfo";
import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import { ServiceSelector } from "../../components/ServiceSelector/ServiceSelector";
import { useState } from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";

export const ContactSection = ({ innerRef }) => {
  const emptyForm = {
    name: "",
    email: "",
    serivces: [],
    postcode: "",
    message: "",
    date: "",
  };
  const [quoteForm, setQuoteForm] = useState(emptyForm);
  const [currentDate, setCurrentDate] = useState();

  const displayHouseFormFieldsCondition =
    quoteForm.serivces.includes("End of tenancy") ||
    quoteForm.serivces.includes("Post renovation");

  const validEmailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  useEffect(() => {
    getCurrentDate();
  }, []);

  const getCurrentDate = () => {
    setCurrentDate(new Date().toISOString().split("T")[0]);
  };

  const displayToast = (type) => {
    switch (type) {
      case "success":
        toast.success(
          <ToastMainText>
            Quote request successfully sent.
            <br />
            <ToastSecondaryText>
              (You will now get a response confirmation email containing the
              final quote)
            </ToastSecondaryText>
          </ToastMainText>
        );
        break;
      case "error":
        toast.error(
          "Ensure that the form is filled out correctly and that the selected date is at least tomorrow."
        );
        break;
      case "email-error":
        toast.error("Form submission failed. Please try again later.");
        break;
      default:
        break;
    }
  };

  const handleFormField = (key, value) => {
    setQuoteForm({ ...quoteForm, [key]: value });
  };

  const handleServiceClick = (service) => {
    if (quoteForm.serivces.includes(service)) {
      let newServices = quoteForm.serivces.filter((s) => s !== service);
      setQuoteForm({ ...quoteForm, serivces: newServices });
    } else {
      setQuoteForm((prevState) => ({
        ...prevState,
        serivces: [...prevState.serivces, service],
      }));
    }
  };

  const validateBooking = () =>
    quoteForm.date > currentDate &&
    quoteForm.email.match(validEmailRegex) &&
    quoteForm.name.length > 0 &&
    quoteForm.serivces.length > 0 &&
    quoteForm.postcode.length > 0;

  const emailSubmission = () => {
    fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        apikey: "2fe312c6-adf6-4a2c-bd9c-7d11988f2716",
        ...quoteForm,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          displayToast("success");
          setQuoteForm(emptyForm);
        } else {
          displayToast("email-error");
        }
      });
  };

  const handleFormSubmit = () => {
    const isFormValid = validateBooking();
    if (isFormValid) {
      emailSubmission();
    } else {
      displayToast("error");
    }
  };

  return (
    <Container ref={innerRef}>
      <SectionHeader
        headerConfig={{
          extra: "Message us",
          title: "Get a quote",
          description: "Call, email or use the online form to get a quote",
        }}
      />
      <ContactDiv>
        <ContactInfo />
        <ContactForm>
          <FormTitle>Quote Form</FormTitle>
          <FormDiv>
            <FormField>
              <FieldLabel>Name</FieldLabel>
              <FormInput
                name="name"
                style={{ textTransform: "capitalize" }}
                type="text"
                onChange={(e) => handleFormField("name", e.target.value)}
                value={quoteForm.name}
              ></FormInput>
            </FormField>
            <FormField>
              <FieldLabel>Email</FieldLabel>
              <FormInput
                name="email"
                type="text"
                onChange={(e) => handleFormField("email", e.target.value)}
                value={quoteForm.email}
              ></FormInput>
            </FormField>
            <FormField>
              <FieldLabel>Postcode</FieldLabel>
              <FormInput
                name="postcode"
                type="text"
                placeholder="e.g. CB7 4ES"
                onChange={(e) => handleFormField("postcode", e.target.value)}
                value={quoteForm.postcode.toUpperCase()}
              ></FormInput>
            </FormField>
            <FormField>
              <FieldLabel>Service</FieldLabel>
              <ServiceSelector
                onServiceClick={handleServiceClick}
                activeServices={quoteForm.serivces}
              ></ServiceSelector>
            </FormField>
            {displayHouseFormFieldsCondition && (
              <HomeDetailsDiv>
                <HomeDetails>House details</HomeDetails>
                <FormField style={{ marginTop: 15 }}>
                  <FieldLabel isHouseFormField>Bedrooms</FieldLabel>
                  <FormNumberInput
                    name="bedrooms"
                    type="number"
                    onChange={(e) =>
                      handleFormField("bedrooms", e.target.value)
                    }
                    value={quoteForm.bedrooms}
                  ></FormNumberInput>
                </FormField>
                <FormField>
                  <FieldLabel isHouseFormField>Bathrooms</FieldLabel>
                  <FormNumberInput
                    name="bathrooms"
                    type="number"
                    onChange={(e) =>
                      handleFormField("bathrooms", e.target.value)
                    }
                    value={quoteForm.bathrooms}
                  ></FormNumberInput>
                </FormField>
                <FormField>
                  <FieldLabel isHouseFormField>Living rooms</FieldLabel>
                  <FormNumberInput
                    name="livingrooms"
                    type="number"
                    onChange={(e) =>
                      handleFormField("livingrooms", e.target.value)
                    }
                    value={quoteForm.livingrooms}
                  ></FormNumberInput>
                </FormField>
                <FormField>
                  <FieldLabel isHouseFormField>
                    Other rooms <OptionalText>(optional)</OptionalText>
                  </FieldLabel>
                  <FormInput
                    name="otherRooms"
                    type="text"
                    placeholder="e.g. 1 guest room 1 laundry room"
                    onChange={(e) =>
                      handleFormField("otherRooms", e.target.value)
                    }
                    value={quoteForm.otherRooms}
                  ></FormInput>
                </FormField>
              </HomeDetailsDiv>
            )}
            <FormField>
              <FieldLabel>Date</FieldLabel>
              <FormInput
                type="date"
                min={currentDate}
                onChange={(e) => handleFormField("date", e.target.value)}
                value={quoteForm.date}
              ></FormInput>
            </FormField>
            <FormField>
              <FieldLabel>
                Message <OptionalText>(optional)</OptionalText>
              </FieldLabel>
              <FormTextArea
                name="message"
                placeholder="I would like..."
                onChange={(e) => handleFormField("message", e.target.value)}
                value={quoteForm.message}
              ></FormTextArea>
            </FormField>
            <SubmitButton onClick={handleFormSubmit}>Get a quote</SubmitButton>
            <CautionText>
              * This form is not used to book your service. We will reply with
              your final quote and the next steps.
            </CautionText>
          </FormDiv>
        </ContactForm>
      </ContactDiv>
    </Container>
  );
};
