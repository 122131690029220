import { ServiceSelectorCopy } from "./ServiceSelectorCopy";
import { Container, ServiceDiv, ServiceText } from "./ServiceSelector.styles";

export const ServiceSelector = ({ onServiceClick, activeServices }) => {
  return (
    <Container>
      {ServiceSelectorCopy.map(({ title }) => (
        <ServiceDiv
          onClick={() => onServiceClick(title)}
          active={activeServices.includes(title)}
          key={title}
        >
          <ServiceText>{title}</ServiceText>
        </ServiceDiv>
      ))}
    </Container>
  );
};
