import {
  BlueHeaderText,
  HeaderDescription,
  HeaderDiv,
  HeaderTitle,
} from "./SectionHeader.styles";

export const SectionHeader = ({ headerConfig }) => {
  const { extra, title, description } = headerConfig;
  return (
    <HeaderDiv>
      <BlueHeaderText>{extra}</BlueHeaderText>
      <HeaderTitle>{title}</HeaderTitle>
      <HeaderDescription>{description}</HeaderDescription>
    </HeaderDiv>
  );
};
