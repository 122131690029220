import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  text-align: center;
  background-color: #222222;
`;

export const SVGContainer = styled.div`
  height: 100px;
`;

export const CopyRightText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  color: white;
  margin-bottom: 10px;
`;

export const PoweredByDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px 0px;
`;

export const BIVText = styled.span`
  font-size: 12px;
  color: white;
  margin-right: 5px;
  font-weight: 500;
`;
