import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  height: 380px;
  text-align: left;
  cursor: default;
  background-color: transparent;
  box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 10px 0px;
  border-radius: 5px;
  padding: 25px;

  @media (max-width: 900px) {
    width: 100%;
    height: 350px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 5px 10px 0px;

    :nth-child(odd) {
      margin-top: 0%;
    }
  }
`;

export const ImageDiv = styled.div`
  height: 250px;
  width: 100%;
  margin-bottom: 10px;

  @media (max-width: 900px) {
    height: 200px;
  }
`;

export const CardImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardTitle = styled.span`
  font-weight: bold;
  font-size: 25px;
  margin: 2% 0px;
  text-align: left;
  color: black;

  @media (max-width: 900px) {
    font-size: 20px;
  }
`;

export const Description = styled.span`
  font-size: 18px;
  width: 100%;
  color: #333;
  margin-top: 5px;
  line-height: 1.5em;
`;
