import { BIVLogo } from "../BIVLogo";
import MainLogo from "../MainLogo";
import {
  BIVText,
  Container,
  CopyRightText,
  PoweredByDiv,
  SVGContainer,
} from "./Footer.styles";

export const Footer = () => {
  return (
    <Container>
      <SVGContainer>
        <MainLogo color="white" />
      </SVGContainer>
      <CopyRightText>© 2024 Veloxx. All rights reserved.</CopyRightText>
      <PoweredByDiv>
        <BIVText>Powered by </BIVText>
        <BIVLogo />
      </PoweredByDiv>
    </Container>
  );
};
